import { Flex } from '@components';
import { ContentfulComponentMap, SEO } from '@components/utils';
import { NormalizedContentfulPageBlocks } from '@ts/index';

const LandingPage = ({ blocks, title, seoMetadata }: NormalizedContentfulPageBlocks) => {
	return (
		<>
			<SEO
				title={seoMetadata?.seoTitle ?? title}
				noindex={seoMetadata?.no_index}
				nofollow={seoMetadata?.no_follow}
				{...(!!seoMetadata?.description ? { description: seoMetadata?.description } : {})}
			/>
			<Flex column>{blocks ? <ContentfulComponentMap blocks={blocks} /> : null}</Flex>
		</>
	);
};

export default LandingPage;
